<!-- Sidebar -->
<section id="sidebar" class="sidebar" [class.is-visible]="!isIntro">
	<div class="corporate">
		<img class="corporate-image" src="../../assets/logo.png">
		<div class="corporate-content">
			<h3>GÉDÉ</h3>
			BUSINESS / CONSULTING
		</div>
	</div>
	<div class="inner">
		<nav>
			<ul>
				<li *ngFor="let section of sections">
					<a [class.active]="section.isActive" (click)="goToSection(section.reference)">{{ section.linkLabel}}</a>
				</li>
			</ul>
		</nav>
	</div>
</section>

<!-- Wrapper -->
<div
	id="wrapper"
	class="homeWrapper"
	[class.extended]="isIntro"
	scrollSpy
	[spiedTags]="['SECTION']"
	(sectionChange)="onSectionChange($event)"
>

	<!-- Intro -->
		<section id="intro" class="wrapper intro fullscreen fade-up">
			<video playsinline autoplay muted loop [class.is-intro]="isIntro" id="bgvid">
				<source src="../../assets/video/hero.mp4" type="video/mp4">
			</video>
			<div class="hero">
				<img class="hero-image" src="../../assets/logo.png">
				<div class="hero-content">
					<h1>GÉDÉ</h1>
					BUSINESS / CONSULTING
				</div>
			</div>
			<div class="inner">
				<h1>La vente complexe moderne</h1>
				<p>Des stratégies, des outils, des méthodes.</p>
				<ul class="actions">
					<li><a (click)="goToSection('contact')" class="button primary scrolly">Prenons un café</a></li>
				</ul>
			</div>
		</section>

	<!-- One -->
		<section id="services" class="wrapper services style2 spotlights">
			<section class="centered">
				<div class="content">
					<div class="inner">
						<h1>Dynamiser votre puissance de vente</h1>
						<p>Les méthodes et outils adaptés à chaque étape</p>
					</div>
				</div>
			</section>
			<section>
				<div class="illustration">
					<img src="../../assets/svg/impact.svg" alt="" data-position="center center" />
				</div>
				<div class="content">
					<div class="inner">
						<h2>L'impact</h2>
						<h5>Améliorer le taux de transformation</h5>
						<p>
							Faire de l'amélioration continue.

							Nous identifions les éléments différenciants qui font progresser et signer !
						</p>
						<ul class="actions" *ngIf="">
							<li><a href="generic.html" class="button">Learn more</a></li>
						</ul>
					</div>
				</div>
			</section>
			<section class="alternate">
				<div class="illustration">
					<img src="../../assets/svg/offer.svg" alt="" data-position="center center" />
				</div>
				<div class="content">
					<div class="inner">
						<h2>L'offre qu'il ne faut pas perdre</h2>
						<h5>Construire un dossier gagnant</h5>
						<p>
							Une réponse puissante séduit sur un marché concurrentiel.
							Nous travaillons sur l'organisation interne, la structure de réponse et la rédaction.
						</p>
						<ul class="actions" *ngIf="">
							<li><a href="generic.html" class="button">Learn more</a></li>
						</ul>
					</div>
				</div>
			</section>
			<section>
				<div class="illustration">
					<img src="../../assets/svg/time.svg" alt="" data-position="center center" />
				</div>
				<div class="content">
					<div class="inner">
						<h2>Le temps est précieux</h2>
						<h5>Gagner du temps !</h5>
						<p>
							A chaque étape sa méthode et ses outils.

							Nous vous formons sur des solutions performantes qui vous font gagner du temps
						</p>
						<ul class="actions" *ngIf="">
							<li><a href="generic.html" class="button">Learn more</a></li>
						</ul>
					</div>
				</div>
			</section>
		</section>

	<!-- Two -->
		<section id="about" class="wrapper about style3 fade-up">
			<div class="inner">
				<h2>Décomposer. <br> Analyser. <br> Optimiser, avec vous.</h2>
				<h5>GEDE est un cabinet spécialisé dans le process de vente complexe en BtoB.</h5>
				<p>
					Nous accompagnons des entreprises de toutes tailles dans l'amélioration continue de leurs étapes commerciales.
					
					Une affaire qu'il faut impérativement remporter ? Nous organisons et construisons la réponse gagnante avec vous.</p>
				<div class="features" *ngIf="">
					<section>
						<span class="icon solid major fa-code"></span>
						<h3>Lorem ipsum amet</h3>
						<p>Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.</p>
					</section>
					<section>
						<span class="icon solid major fa-lock"></span>
						<h3>Aliquam sed nullam</h3>
						<p>Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.</p>
					</section>
					<section>
						<span class="icon solid major fa-cog"></span>
						<h3>Sed erat ullam corper</h3>
						<p>Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.</p>
					</section>
					<section>
						<span class="icon solid major fa-desktop"></span>
						<h3>Veroeros quis lorem</h3>
						<p>Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.</p>
					</section>
					<section>
						<span class="icon solid major fa-link"></span>
						<h3>Urna quis bibendum</h3>
						<p>Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.</p>
					</section>
					<section>
						<span class="icon major fa-gem"></span>
						<h3>Aliquam urna dapibus</h3>
						<p>Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.</p>
					</section>
				</div>
				<ul class="actions" *ngIf="">
					<li><a href="generic.html" class="button">Learn more</a></li>
				</ul>
			</div>
		</section>

	<!-- Three -->
		<section id="contact" class="wrapper style1 fade-up">
			<div class="inner">
				<h2>Rencontrons-nous</h2>
				<p>L'échange est merveilleux</p>
				<div class="split style1">
					<section>
						<form method="post" action="#">
							<div class="fields">
								<div class="field half">
									<label for="name">Nom</label>
									<input type="text" name="name" id="name" />
								</div>
								<div class="field half">
									<label for="email">Email</label>
									<input type="text" name="email" id="email" />
								</div>
								<div class="field">
									<label for="message">Message</label>
									<textarea name="message" id="message" rows="5"></textarea>
								</div>
							</div>
							<ul class="actions">
								<li><a href="" class="button submit">Envoyer</a></li>
							</ul>
						</form>
					</section>
					<section>
						<ul class="contact">
							<li>
								<h3>
									<i class="material-icons">location_on</i> Adresse
								</h3>
								<span>
									1 Allée Louis Jouvet, 92600 Asnières-sur-Seine</span>
							</li>
							<li>
								<h3>
									<i class="material-icons">mail</i> Email
								</h3>
								<a href="#">a.audousset@ge-de.fr</a>
							</li>
							<li>
								<h3>
									<i class="material-icons">phone</i> Téléphone
								</h3>
								<span>+33 6 88 10 17 92</span>
							</li>
							<li>
								<h3>Social</h3>
								<ul class="icons">
									<li><a href="#" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>
									<li><a href="#" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
									<li><a href="#" class="icon brands fa-github"><span class="label">GitHub</span></a></li>
									<li><a href="#" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
									<li><a href="#" class="icon brands fa-linkedin-in"><span class="label">LinkedIn</span></a></li>
								</ul>
							</li>
						</ul>
					</section>
				</div>
			</div>
		</section>

</div>

<!-- Footer -->
<footer id="footer" class="wrapper footer">
	<div class="inner">
		<ul class="menu">
			<li>&copy; 2020 GEDE. Tous droits réservés.</li>
			<li>Design: Made by <a href="https://www.malt.fr/profile/alexisfalaise">Alexis Falaise</a> 
				- Based on Hyperspace<a href="http://html5up.net"> from HTML5up</a></li>
		</ul>
	</div>
</footer>