import { Component } from '@angular/core';

interface HomeSection {
  reference: string;
  linkLabel: string;
  isActive: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  sections: HomeSection[] = [
    {
      reference: 'intro',
      linkLabel: 'Accueil',
      isActive: true,
    },
    {
      reference: 'services',
      linkLabel: 'Services',
      isActive: false,
    },
    { 
      reference: 'about',
      linkLabel: 'A propos',
      isActive: false,
    },
    {
      reference: 'contact',
      linkLabel: 'Contact',
      isActive: false,
    },
  ];

  get isIntro(): boolean {
    return this.getSectionByReference('intro').isActive;
  }

  getSectionByReference(reference: string): HomeSection {
    return this.sections.find(section => section.reference = reference);
  }

  constructor() { }

  goToSection(elementId: string): void {
    const element = window.document.getElementById(elementId);
    element.scrollIntoView({behavior: 'smooth'});
  }

  onSectionChange(reference: string): void {
    this.sections.forEach(section => section.isActive = false);
    this.sections.find(section => section.reference === reference).isActive = true;
  }

}
