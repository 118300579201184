import { Component, OnInit } from '@angular/core';

enum UserType {
  banane = 'Banane',
  pomme = 'Pomme'
}

interface User {
  type: UserType;
  description: string;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  users: User[] = [
    {
      type: UserType.banane,
      description: "Vous avez toujours la pêche",
    },
    {
      type: UserType.pomme,
      description: "La gravité ça vous connaît",
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
